<template>
  <div>
    <b-card v-bind:class="hasAcquisition === 1 ? 'mb-5' : 'mb-2'">
      <b-overlay :show="loading" rounded="sm">
        <b-card-header class="d-inline-block mb-2">
          <h4 class="float-left">Detail Merchant</h4>
          <b-badge class="ml-1" :variant="statusVariant(datas.status)">
            {{ statusName(datas.status) }}
          </b-badge>
          <b-badge
            v-if="datas.status === 2 && hasAcquisition === 1"
            class="ml-1"
            variant="light-primary"
            >Done Trx 10k</b-badge
          >
        </b-card-header>
        <b-tabs pills>
          <b-tab title="Merchant" active>
            <b-row>
              <b-col md="6" xl="4">
                <b-form-group label="NMID" label-for="nmid">
                  <b-form-input id="nmid" v-model="datas.nmid" readonly />
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4">
                <b-form-group label="Nama QR" label-for="name">
                  <b-form-input id="name" v-model="datas.name" readonly />
                </b-form-group>
              </b-col>
            </b-row>
            <hr class="my-2" />
            <b-row>
              <b-col xl="12">
                <b-form-group label="Alamat Pengunaan QR" label-for="address">
                  <b-form-textarea
                    id="address"
                    v-model="datas.address"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4">
                <b-form-group label="Kode Pos" label-for="postal-code">
                  <b-form-input
                    id="postal-code"
                    v-model="datas.postal_code"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4">
                <b-form-group label="Desa / Kelurahan" label-for="village">
                  <b-form-input id="village" v-model="datas.village" readonly />
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4">
                <b-form-group label="Kecamatan" label-for="district">
                  <b-form-input id="email" v-model="datas.district" readonly />
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4">
                <b-form-group label="Kota / Kabupaten" label-for="city">
                  <b-form-input id="city" v-model="datas.city" readonly />
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4">
                <b-form-group label="Provinsi" label-for="province">
                  <b-form-input
                    id="province"
                    v-model="datas.province"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <hr class="my-2" />
            <b-row>
              <b-col md="6" xl="4">
                <b-form-group label="Jenis usaha" label-for="business-type">
                  <b-form-input
                    id="business-type"
                    v-model="datas.business_type_name"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4">
                <b-form-group
                  label="Tipe Bidang Usaha"
                  label-for="category-type"
                >
                  <b-form-input
                    id="category-type"
                    v-model="datas.merchant_type_name"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4">
                <b-form-group label="Bidang Usaha" label-for="category">
                  <b-form-input
                    id="category"
                    v-model="datas.merchant_category_name"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4">
                <b-form-group
                  label="Rata-rata Omset per Bulan"
                  label-for="omzet"
                >
                  <b-form-input
                    id="omzet"
                    v-model="datas.omzet_name"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="datas.business_type == 2">
              <b-col cols="12">
                <hr class="my-2" />
              </b-col>
              <b-col md="6" xl="4">
                <b-form-group
                  label="Nama Badan Hukum"
                  label-for="business_name"
                >
                  <b-form-input
                    id="business_name"
                    v-model="datas.business_name"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4">
                <b-form-group label="NPWP" label-for="npwp">
                  <b-form-input id="npwp" v-model="datas.npwp" readonly />
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Penanggung Jawab">
            <b-row>
              <b-col md="6" xl="6">
                <b-form-group label="Nama Lengkap" label-for="name">
                  <b-form-input
                    id="name"
                    v-model="datas.profiles.name"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" xl="6">
                <b-form-group label="No. Handphone" label-for="phone">
                  <b-form-input
                    id="phone"
                    v-model="datas.profiles.phone"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" xl="6">
                <b-form-group label="NIK E-KTP" label-for="id-card">
                  <b-form-input
                    id="id-card"
                    v-model="datas.profiles.nik"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" xl="6">
                <b-form-group label="Email" label-for="email">
                  <b-form-input
                    id="email"
                    v-model="datas.profiles.email"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col xl="12">
                <b-form-group label="Alamat Tempat Tinggal" label-for="address">
                  <b-form-textarea
                    id="address"
                    v-model="datas.profiles.address"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" xl="6">
                <b-form-group label="Kode Pos" label-for="postal-code">
                  <b-form-input
                    id="postal-code"
                    v-model="datas.profiles.postal_code"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" xl="6">
                <b-form-group label="Desa / Kelurahan" label-for="village">
                  <b-form-input
                    id="village"
                    v-model="datas.profiles.village"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" xl="6">
                <b-form-group label="Kecamatan" label-for="district">
                  <b-form-input
                    id="email"
                    v-model="datas.profiles.district"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" xl="6">
                <b-form-group label="Kota / Kabupaten" label-for="city">
                  <b-form-input
                    id="city"
                    v-model="datas.profiles.city"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" xl="6">
                <b-form-group label="Provinsi" label-for="province">
                  <b-form-input
                    id="province"
                    v-model="datas.profiles.province"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Rekening Bank">
            <b-row>
              <b-col md="6" xl="4">
                <b-form-group label="Nama Bank" label-for="bank-name">
                  <b-form-input
                    id="bank-name"
                    v-model="datas.banks.bank_name"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" xl="4">
                <b-form-group label="No. Rekening" label-for="account-no">
                  <b-form-input
                    id="account-no"
                    v-model="datas.banks.account_no"
                    readonly
                  /> </b-form-group
              ></b-col>
              <b-col md="6" xl="4">
                <b-form-group label="Nama Rekening" label-for="account-name">
                  <b-form-input
                    id="account-name"
                    v-model="datas.banks.account_name"
                    readonly
                  /> </b-form-group
              ></b-col>
            </b-row>
          </b-tab>
          <b-tab title="Data Pendukung">
            <b-row>
              <b-col md="6" xl="3">
                <b-form-group label="Foto KTP">
                  <b-img
                    thumbnail
                    fluid
                    :src="images.ktp"
                    v-b-modal.modal-center
                    @click="showImage(images.ktp)" /></b-form-group
              ></b-col>
              <b-col md="6" xl="3">
                <b-form-group label="Foto lokasi merchant">
                  <b-img
                    thumbnail
                    fluid
                    :src="images.location"
                    v-b-modal.modal-center
                    @click="showImage(images.location)" /></b-form-group
              ></b-col>
              <b-col md="6" xl="3" v-if="datas.business_type === 2">
                <b-form-group label="NPWP">
                  <b-img
                    thumbnail
                    fluid
                    :src="images.npwp"
                    v-b-modal.modal-center
                    @click="showImage(images.npwp)" /></b-form-group
              ></b-col>
              <b-col md="6" xl="3">
                <b-form-group label="Form, Syarat dan Ketentuan">
                  <b-button
                    block
                    class="mt-1 mb-3"
                    variant="primary"
                    @click="downloadImg(images.terms)"
                    >Lihat Dokumen</b-button
                  >
                </b-form-group></b-col
              >
            </b-row>
            <b-modal id="modal-center" centered title="" ok-only size="lg">
              <div class="text-center">
                <b-img :src="media" class="img-fluid" />
              </div>
            </b-modal>
          </b-tab>
          <b-tab title="QR" v-if="datas.status === 2">
            <b-row>
              <b-col md="6" xl="3">
                <b-form-group label="QR Merchant">
                  <b-img thumbnail fluid :src="images.qr"
                /></b-form-group>
                <b-button
                  v-if="datas.has_soft_qr === 0"
                  block
                  class="mt-2"
                  variant="success"
                  @click="downloadImg(images.qr)"
                  >Download</b-button
                >
                <b-button
                  v-if="datas.has_soft_qr !== 0"
                  block
                  class="mt-2 mb-3"
                  variant="success"
                  @click="downloadImg(images.qr)"
                  >Download</b-button
                >
                <b-button
                  v-if="datas.has_soft_qr === 0"
                  block
                  class="mt-1 mb-3"
                  variant="success"
                  @click="onProven"
                  >Upload Bukti Terpasang</b-button
                >
              </b-col>
              <b-col md="6" xl="3" v-if="datas.has_soft_qr === 1">
                <b-form-group label="Bukti QR terpasang">
                  <b-img
                    thumbnail
                    fluid
                    :src="images.soft_qr"
                    v-b-modal.modal-center
                    @click="showImage(images.soft_qr)"
                /></b-form-group>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-overlay>
    </b-card>
    <b-button
      v-if="datas.status === 2 && hasAcquisition === 0"
      block
      variant="success"
      class="mb-5"
      @click="setAcquisition"
      >Done Trx 10k</b-button
    >
  </div>
</template>

<script>
import { $themeConfig } from "@themeConfig";
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BTab,
  BTabs,
  BImg,
  BModal,
  VBModal,
  BBadge,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BTab,
    BTabs,
    BButton,
    BImg,
    BModal,
    VBModal,
    BBadge,
  },
  directives: {
    Ripple,
  },
  computed: {
    statusVariant() {
      const statusColor = {
        0: "light-secondary",
        1: "light-primary",
        2: "light-success",
        3: "light-warning",
      };

      return (status) => statusColor[status];
    },
    statusName() {
      const statusName = {
        0: "Uncompleted",
        1: "Waiting Confirmation",
        2: "Approved",
        3: "Rejected",
      };

      return (status) => statusName[status];
    },
  },
  setup() {
    const { baseUrl } = $themeConfig.app;
    return {
      baseUrl,
    };
  },
  data() {
    return {
      loading: false,
      merchantId: null,
      businessType: null,
      datas: null,
      media: null,
      hasAcquisition: null,
      images: {
        ktp: null,
        npwp: null,
      },
    };
  },
  created() {
    this.merchantId = this.$store.state.app.merchantId;

    if (this.$store.state.app.merchantId === null) {
      this.merchantId = localStorage.getItem("merchantId", this.merchantId);
    }

    this.businessType = this.$store.state.app.businessType;

    if (this.$store.state.app.businessType === null) {
      this.businessType = localStorage.getItem(
        "businessType",
        this.businessType
      );
    }
    this.getDatas();
  },
  methods: {
    getDatas() {
      this.$http.get("/merchants/" + this.merchantId).then((res) => {
        var $datas = res.data.data;
        this.datas = $datas;
        // console.log(this.datas)
        this.images.ktp =
          this.baseUrl + "image/merchant/ktp/" + $datas.images.ktp;
        this.images.npwp =
          this.baseUrl + "image/merchant/npwp/" + $datas.images.npwp;
        this.images.location =
          this.baseUrl + "image/merchant/location/" + $datas.images.location;
        this.images.terms =
          this.baseUrl + "merchant/terms/" + $datas.images.terms;
        this.images.qr = this.baseUrl + "image/merchant/qr/" + $datas.images.qr;
        this.images.soft_qr =
          this.baseUrl + "image/merchant/soft-qr/" + $datas.images.soft_qr;
        this.images.starter_pack =
          this.baseUrl +
          "image/merchant/starter-pack/" +
          $datas.images.starter_pack;

        this.hasAcquisition = $datas.has_acquisition;
      });
    },
    showImage($filename) {
      this.media = $filename;
    },
    onProven() {
      this.$store.state.app.merchantId = this.merchantId;
      localStorage.setItem("merchantId", this.merchantId);
      this.$router.push("/merchant/add/soft-qr");
    },
    downloadImg($link) {
      window.open($link, "_blank");
    },
    setAcquisition() {
      this.loading = true;
      this.$http
        .post("/merchants/" + this.merchantId + "/acquisition")
        .then((res) => {
          this.loading = false;
          this.$bvToast.toast("Berhasil", {
            title: "Berhasil",
            variant: "success",
            solid: true,
            autoHideDelay: 1000,
          });
          this.hasAcquisition = 1;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$bvToast.toast(err.response.data.message, {
            title: "Error",
            variant: "danger",
            solid: true,
            autoHideDelay: 1000,
          });
        });
    },
  },
};
</script>

<style>
</style>
